/* 封装axios方法 */

// 引入axios

import axios from 'axios'

// stringify 转序列化数据为 name=mumu&age=18
// parse把 name=mumu&age=18 转成{name:"mumu",age:18}
//import qs from 'qs';

// 请求取消
const CancelToken = axios.CancelToken
const source = CancelToken.source()

// 创建axios实例
const httpService = axios.create({
    // url前缀-'https://some-domain.com/api/'
    //baseURL: process.env.VUE_APP_BASE_API, // 需自定义？？有可能有不同地址的时候
    // 请求超时时间
    timeout: 300000, // 需自定义
    cancelToken: source.token // 取消请求（比如你进入某个路由后，可能就停在了这个页面就1s时间， 就立马进入了下个路由，这时，前边这个路由的请求就可以取消掉了）
})
// 直接调用source.cancel('请求被取消')，接口会取消请求，可以在路由离开当前页面后，调用一下;

// request请求拦截
httpService.interceptors.request.use(
    config => {
        return config
    },
    error => {
        // 请求错误处理
        console.log('<======发起请求异常=======>：', error)
        Promise.reject(error)
    }
)

// response响应拦截
// 响应拦截器
httpService.interceptors.response.use(
    // 请求成功
    res => {
        if (res.status === 200) {
            let response = res.data
            //处理数据成功之后的返回的错误信息

            return Promise.resolve(response)
        } else {
            return Promise.reject(res)
        }
    },
    // 请求失败
    error => {
        const { response } = error
        if (response) {
            // 请求已发出，但是不在2xx的范围
            errorHandle(response.status, response.data.message, response)
            return Promise.reject(response)
        } else {
            // 处理断网的情况
            // eg:请求超时或断网时，更新state的network状态
            // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
            // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
            //store.commit("changeNetwork", false);
            const isTimeout = error.code === 'ECONNABORTED' && error.message.includes('timeout')
            if (isTimeout) errorHandle(408, null, response)
            // return Promise.reject(error)
        }
    }
)

//默认错误信息
const DEFAULT_ERROR_MESSAGE = '服务异常'

/**
 * 错误提示消息
 */
const ERROR_MESSAGE = {
    // 401: '登录状态已过期，请重新登录',
    // 403: '服务器拒绝请求',
    // 404: '哎呀~ (ಥ﹏ಥ)网络又开小差了,请稍后刷新重试!',
    408: '请求超时'
    // 500: '服务器内部错误',
    // 501: '尚未实施',
    // 502: '错误网关',
    // 503: '服务不可用'
}

/**
 * @description: 除200外的错误处理
 * @param {Number} status 状态码
 * @param {String} message 错误信息
 * @param {Object} res reponse
 */
const errorHandle = (status, message, res) => {
    if (status && !message) {
        message = ERROR_MESSAGE[status] || DEFAULT_ERROR_MESSAGE
    }
}

export default httpService
export { ERROR_MESSAGE, errorHandle }
