/*
 * @Description:
 * @Author: leimin
 * @Date: 2022-05-24 10:47:43
 * @LastEditTime: 2023-01-07 22:43:54
 * @LastEditors: leimin
 */
import Vue from 'vue'
import Router  from 'vue-router'
import routes from './config.js'

Vue.use(Router )

const router = new Router  ({
    
    mode: 'history',  
    routes 
})

export default router
