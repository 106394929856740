/*
 * @Description:
 * @Author: shufei
 * @Date: 2023-01-07 17:58:17
 * @LastEditTime: 2023-01-07 22:53:55
 * @LastEditors: leimin
 */
import router from '@/router'
router.beforeEach(async (to, from, next) => {
    if (to.meta.title) {
        // 判断是否有标题
        document.title = to.meta.title
    }
    return next()
})
