/*
 * @Description:
 * @Author: leimin
 * @Date: 2022-04-20 14:36:56
 * @LastEditTime: 2023-01-07 23:33:09
 * @LastEditors: leimin
 */
import Vue from 'vue'
import App from './App.vue'
import api from '@/api'
import '@/router/permission.js'
import router from './router'
// 引入全局样式
import '@/assets/css/common.scss'
Vue.config.productionTip = false
Vue.prototype.$api = api
  
new Vue({
    render: h => h(App),
    router
}).$mount('#app')
