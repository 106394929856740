/*
 * @Description: 封装请求
 * @Author: Joker
 * @Date: 2020-12-08 16:45:02
 * @LastEditTime: 2023-01-07 18:17:44
 * @LastEditors: shufei
 */
/*网络请求部分*/
import http from './request.js'
import axios from 'axios'
import qs from 'qs'
const CancelToken = axios.CancelToken

/*
 *  get请求
 *  url:请求地址
 *  params:参数
 * */
export function get(url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
        http({
            url: url,
            method: 'get',
            params: params,
            //缓存未相应接口
            cancelToken: new CancelToken(function executor(c) {
                window.requestList && window.requestList.push(c) //存储cancle
            }),
            ...config
        })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function post(url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
        http({
            url: process.env.NODE_ENV === 'development' ? '/' + process.env.VUE_APP_DEV + url : url,
            method: 'post',
            data: params,
            //缓存未相应接口
            cancelToken: new CancelToken(function executor(c) {
                //如果不是轮训的权限接口 取消其他所有请求
                if (!url.includes('getbaseconfig')) {
                    window.requestList && window.requestList.push(c) //存储cancle
                }
            }),
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            ...config
        })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export default {
    get,
    post
}
