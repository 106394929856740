<!--
 * @Description: 
 * @Author: leimin
 * @Date: 2022-04-20 14:36:56
 * @LastEditTime: 2023-01-07 23:41:24
 * @LastEditors: leimin
-->
<template>
    <div id="app">

       <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'App',
    components: {},
    
}
</script>

<style lang="scss">

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    color: #222222;
}
</style>
