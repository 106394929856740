/*
 * @Description:
 * @Author: leimin
 * @Date: 2022-05-24 11:44:43
 * @LastEditTime: 2023-04-11 14:52:29
 * @LastEditors: leimin
 */

const routes = [
    {
        path: '/',
        component: () => import('@/layouts/index'),
        redirect: '/pages',
        meta: {
            title: '田英章 书法字帖'
        },
        children: [
            {
                path: '/pages/:id',
                name: 'Pages',
                meta: {
                    title: '田英章 书法字帖'
                },
                component: () => import('@/view/pages')
            }
        ]
    }
]
export default routes
