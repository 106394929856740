/*
 * @Description:
 * @Author: shufei
 * @Date: 2023-01-07 18:14:53
 * @LastEditTime: 2023-01-07 23:28:54
 * @LastEditors: leimin
 */
import http from '@/util/request/https'
const BASE_API =  process.env.VUE_APP_BASE_URL
const GetPages = param => {
    return http.get(`${BASE_API}v1/pages/${param}`) //通过code获取登陆信息
}
export default {
    GetPages
}
